//import { initSplash } from './components/splash';

export async function initApp (): Promise<void> {
  //try {
    //await initSplash();
  //} catch (e) {
   // console.error(e);
  //}

  console.log('Initialized');
}
